import React from 'react';
import { createRoot } from 'react-dom/client';
import { AuthProvider } from 'react-oidc-context';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { datadogRum } from '@datadog/browser-rum';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ApolloClientProvider from './ApolloClientProvider';
import FeaturesProvider from 'contexts/Features.context';
import { getEnv } from './utils/environment';
import NavBarProvider from 'contexts/NavBar.context';
import Router from './Router';
import { LocalStorageProvider } from './contexts/LocalStorageContext';
import theme from './theme';
import NotificationProvider from 'contexts/Notification.context';
import RolesProvider from 'contexts/Roles.context';
import UserProfileProvider from 'contexts/UserProfile.context';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

const forgerockUri = window.location.origin.includes('one.cube.toyota.com')
  ? 'https://ep.fram.idm.toyota.com/openam/oauth2/realms/root/realms/dealerdaily'
  : 'https://ep.fram.qa.idm.toyota.com/openam/oauth2/realms/root/realms/dealerdaily';

const oidcConfig = {
  onSigninCallback: () => {
    const pathname = sessionStorage.getItem('cube-pathname') || '';
    const search = sessionStorage.getItem('cube-search') || '';
    sessionStorage.removeItem('cube-pathname');
    sessionStorage.removeItem('cube-search');
    window.history.replaceState(null, '', `${window.location.origin}${pathname}${search}`);
  },
  authority: forgerockUri,
  client_id: 'DSMETACT',
  redirect_uri: window.location.origin,
  scope: 'openid profile',
  post_logout_redirect_uri: window.location.origin,
};

const env = getEnv();

const isProduction = process.env.NODE_ENV === 'production';

if (isProduction) {
  datadogRum.init({
    applicationId: '323b4851-ed05-47eb-92e6-4282e9dde500',
    clientToken: 'pube4f299a815260c4461cdcc4a2b7631c1',
    site: 'datadoghq.com',
    service: 'cube-pipeline-ui',
    env,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
  });

  datadogRum.setGlobalContextProperty('account_id', '091975348311');
  datadogRum.startSessionReplayRecording();
}

const queryClient = new QueryClient();

root.render(
  <AuthProvider {...oidcConfig}>
    <ApolloClientProvider>
      <LocalStorageProvider>
        <ThemeProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <CssBaseline />
            <FeaturesProvider>
              <RolesProvider>
                <NavBarProvider>
                  <NotificationProvider>
                    <UserProfileProvider>
                      <Router />
                    </UserProfileProvider>
                  </NotificationProvider>
                </NavBarProvider>
              </RolesProvider>
            </FeaturesProvider>
          </QueryClientProvider>
        </ThemeProvider>
      </LocalStorageProvider>
    </ApolloClientProvider>
  </AuthProvider>,
);
